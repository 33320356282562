<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getNotifyUsersRegistry.name"
                    :avatarData="getNotifyUsersRegistry.currentMenager"
                    @clearSearch="clearTable"
                />

                <Table
                    :title="getNotifyUsersRegistry.name"
                    :items="getNotifyUsersTable.items"
                    :headers="headers"
                    :length="getNotifyUsersTable.pages"
                    :page="getNotifyUsersTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getNotifyUsersSearchFields.searchFields"
                    :loading="getNotifyUsersTable.loading"
                >
                    <Columns
                        slot="columns"
                        :items="getNotifyUsersTable.items"
                    />
                </Table>
                <Modal
                    :title="$t('notify-users:newUser')"
                    :open="open"
                    :height="'640'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addUser()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('offers:addOffer') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import store from '../../../store/index'
import Columns from '../../../components/Registries/Notify-users/Table/Columns.vue'
import AEContent from '../../../components/Registries/Notify-users/Modal/Content.vue'
import Buttons from '../../../components/Registries/Notify-users/Modal/Buttons.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('notify-users:name&LastName'),
                    value: 'name join lastName',
                    width: '12%',
                    showSearch: true,
                },
                {
                    text: this.$t('notify-users:companyName'),
                    value: 'contact.name',
                    width: 'auto',
                    showSearch: true,
                    disabled: true,
                },
                {
                    text: this.$t('notify-users:createdAt'),
                    value: 'createdAt',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('notify-users:contacts'),
                    value: 'login join phoneNumber',
                    width: '15%',
                    align: 'left',
                    sortable: false,
                    showSearch: true,
                },

                {
                    text: this.$t('notify-users:type'),
                    value: 'type',
                    width: '15%',
                    align: 'left',
                    showSearch: false,
                },
                {
                    text: this.$t('global:status'),
                    value: 'status.name',
                    width: '100px',
                    align: 'left',
                    showSearch: true,
                },

                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getNotifyUsersTable',
            'getNotifyUsersSearchFields',
            'getNotifyUsersRegistry',
            'getProfileDetails',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchNotifyUsersTable',
            'fetchAllDepartments',
            'fetchUsers',
        ]),
        ...mapMutations([
            'clearNotifyUsersModal',
            'clearNotifyUsersErrors',
            'setNotifyUsersTable',
            'setNotifyUsersSearch',
            'clearNotifyUsersTable',
        ]),
        closeModal() {
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        async addUser() {
            this.open = true
            this.clearNotifyUsersErrors()
            this.clearNotifyUsersModal()
        },
        setSearchFields(searchFields) {
            this.setNotifyUsersSearch(searchFields)
        },
        setSearch(search) {
            this.setNotifyUsersTable({ search, page: 1 })
            this.fetchNotifyUsersTable()
        },
        setPage(page) {
            this.setNotifyUsersTable({ page })
            this.fetchNotifyUsersTable()
        },
        setSort(sort) {
            this.setNotifyUsersTable({ sort })
            this.fetchNotifyUsersTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearNotifyUsersTable()
            this.fetchNotifyUsersTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/help-desk-users/:id')
            store.commit('clearNotifyUsersTable')
        store.dispatch('fetchNotifyUsersTable').then(() => next())
    },
}
</script>
